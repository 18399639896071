.register.login h2 {
  font-size: 28px;
  margin: 0 auto;
}

.container.success h2 {
  margin-top: 100px; 
}

.container.success h3 {
  font-size: 22px;
  font-weight: 200;
}

.container.success button {
  margin-top: 50px; 
}

.phone-group select {
  width: 5vw;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.phone-group input {
  width: 18vw;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.terms-group {
  display: inline-flex;
  margin: 6px 0 15px;
}

.terms-group input {
  width: 3vw;
  height: 20px;
}

.terms-group p {
  padding: 0;
  width: 21vw;
  text-align: left;
}

p.no-margin {
  margin: 0;
}

@media screen and (max-width: 736px) {
	.phone-group select {
    width: 18vw;
  }

  .phone-group input {
     width: 49vw
  }

  .terms-group input {
    width: 6vw;
  }

  .terms-group p {
    width: 54vw;
  }
}