@import '../Global.scss';

/* Fondo */

.login {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    background-color: #254370;
    min-height: -webkit-fill-available;
}
.login__heading {
    width: 40%;
}
/* Icono del Titulo */
.Trazado-75 {
    height: 45.6px;
    background-color: #254370;
}

/* Nombre del Titulo */
.Trazado-74 {
    height: 31.3px;
    background-color: #ffffff;
}

/* Texto */
.login h2 {
    margin: 0 auto 60px;
    font-family: 'Open Sans', sans-serif;
    font-size: 36px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #f2f2f2;
    width: 50%;
}

/* Inputs */

.input-group {
    display: grid;
    width: fit-content;
    margin: 0px auto 10px;
}

input,
button,
select {
    border: none;
    width: 23vw;
}
/* label */
.input-group label {
    height: 17px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

/* Input */
.input-group input,
.input-group select {
    height: 32px;
    border-radius: 16px;
    background-color: #ffffff;
    padding: 0 10px 0 10px;
}

/* Botones ingresar */
.formButton {
    margin: 0 auto 13px;
    display: block;
    height: 32.1px;
    border-radius: 16px;
    background-color: #0db8b2;
    color: #e7e7e7;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
}

.login p {
    padding: 10px 0 15px;
    font-size: 12px;
}

.login p.no-margin {
    margin: 0;
}

/* Textos de botones ingresar */
.facebook {
    height: 17px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: 0.6px;
    text-align: left;
    color: #e7e7e7;
}

/* Texto de abajo */
.google {
    height: 17px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.signup {
    color: #0db8b2;
    text-decoration: none;
}

.errors {
    color: red;
}

@media screen and (max-width: 736px) {
    .login {
        width: 100%;
    }

    .login h2 {
        width: 100%;
        font-size: 31px;
        margin: 0 auto 37px;
    }

    input,
    button,
    select {
        width: 67vw;
    }
}

.social-networks {
    display: grid;
}
.social-networks button {
    margin: 0 auto 13px;
    display: block;
    height: 32.1px;
    border-radius: 16px !important;
    background-color: #0db8b2 !important;
    color: #e7e7e7 !important;
    font-weight: bold !important;
    text-transform: uppercase;
    font-size: 12px !important;
    border: none !important;
    padding: 0 !important;
    box-shadow: none !important;
}

.social-networks button span {
    font-weight: bold !important;
    margin: 0 auto;
}
.social-networks div {
    display: none;
}

.success.container.login {
    max-width: unset;
}

@media (max-width: 736px) {
    .login {
        height: fit-content;
    }
}
