@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    color: white;
    font-family: 'Open Sans', sans-serif;
    height: 100%;
}

.container-main {
    background-color: #f2f3f7;
    height: 100%;
    width: 100vw;
    overflow-x: hidden;
}

textarea:focus, input:focus, button:focus{
    outline: none;
}

button {
    cursor: pointer;
}
