/* Header */ 

.header {
  display: inline-flex;
  margin: 70px 0 20px;
}

.header p {
  width: 85px;
  font-size: 9px;
  text-align: left;
  margin-top: 4px;
  margin-left: 10px;
}

img {
  height: 45px;
}