@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
.dropdown {
  height: 50px;
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown button {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  outline: none;
}

.dropdown button:hover {
  color: lightgrey;
}

.dropdown button:focus ul {
  opacity: 1;
  pointer-events: all;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.user {
  position: relative;
  display: flex;
  flex-direction: row;
}

.user button {
  width: 100%;
}

.user ul {
  pointer-events: none;
  position: absolute;
  background: white;
  margin-top: 30px;
  margin-left: 35px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  list-style: none;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  transition: all 0.4s ease;
}

.user p {
  color: black;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
}

.user li {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user li:hover {
  background-color: #88bbe3;
}

.user ul.visible {
  opacity: 1;
  pointer-events: all;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
/*# sourceMappingURL=UserDropdown.css.map */
.dashboard-container {
  background-color: #f4f6f8;
  width: 100%;
  min-height: 100%;
}

.navbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  background-color: #254370;
  justify-content: space-between;
  padding: 20px;
}

.navbar-logo {
  display: flex;
  flex-direction: row;
}

.navbar-logo-text {
  display: block;
  font-size: 10px;
  margin: 10px 0 0 10px;
  width: 160px;
}

.navbar-userArea {
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: space-between;
  margin-right: 30px;
}

.navbar-userArea span {
  background-color: red;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  text-align: center;
  color: #fafafa;
}

.navbar-userArea i {
  margin-top: 7px;
}

.navbar-userArea-notifications {
  display: flex;
}

.navbar img {
  cursor: pointer;
}
/*# sourceMappingURL=navbar.css.map */
.account-wrapper__button {
  width: 10em;
  text-transform: uppercase;
  margin: 5px 10px 10px 10px;
  border-radius: 15px;
  font-size: 0.7em;
  padding: 7px;
  color: #f2f3f7;
}

.card-more-dropdown {
  display: none;
  cursor: pointer;
}

.--create-account {
  background-color: #2c3062;
  border: 1px solid #21b3a8;
}

.--log-in {
  background-color: #21b3a8;
}

@media (max-width: 767px) {
  .account-wrapper__button {
    display: none;
  }
  .card-more-dropdown {
    display: block;
  }
  .card-more-dropdown ul {
    position: absolute;
    background: white;
    margin-top: 30px;
    margin-left: 35px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    list-style: none;
    border-radius: 5px;
    opacity: 1;
    pointer-events: none;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    transition: all 0.4s ease;
  }
  .card-more-dropdown ul.visible {
    opacity: 1;
    pointer-events: all;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
/*# sourceMappingURL=AccountWrapper.css.map */
.back {
  cursor: pointer;
}

.delivery-status-bar {
  padding: 0 15px;
  width: 100%;
  height: 48px;
  background-color: #0db8b2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.delivery-status-bar a {
  text-decoration: none;
  color: #f2f2f2;
  margin: 0px 15px 0px 15px;
  padding: 0px 0px 5px 0px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.delivery-status-bar .underlined {
  border-bottom: 2px solid #f2f2f2;
}

.delivery-status-bar-timer {
  margin: auto;
  width: 580px;
  display: flex;
  flex-direction: row;
  font-weight: 100;
  font-size: 22px;
  padding-left: 15%;
}
/*# sourceMappingURL=StatusBar.css.map */
.query {
  background-color: #f2f3f7;
  height: -webkit-max-content;
  height: max-content;
}

.query-wrapper {
  color: #32376f;
  width: 50%;
  margin: 50px auto;
  text-align: center;
}

.query-wrapper__header {
  font-size: 2.2em;
  font-weight: 300;
}

.query-wrapper__subheader {
  font-size: 1em;
  margin: 25px 0;
}

.query-wrapper__input {
  width: 185px;
  font-weight: 300;
  height: 40px;
  font-size: 1.5em;
  border-radius: 15px;
  margin: 15px auto;
  padding: 0 10px;
  display: block;
  color: #32376f;
}

.query-wrapper__button {
  background-color: #32376f;
  font-size: 14px;
  height: 30px;
  border-radius: 15px;
  color: #f2f3f7;
  width: 120px;
  margin: 25px 0;
}

.query-wrapper__button.disabled {
  background-color: grey;
  cursor: unset;
}

.square {
  color: #f2f3f7;
  border-right: 16px solid #149187;
}

.query-wrapper .square--query {
  width: 150px;
  height: 120px;
  margin: 80px auto;
}

@media (max-width: 320px) {
  .query {
    font-size: 12px;
  }
  .query-wrapper__input {
    width: 160px;
  }
}
/*# sourceMappingURL=Query.css.map */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

body {
  color: white;
  font-family: 'Open Sans', sans-serif;
  height: 100%; }

.container-main {
  background-color: #f2f3f7;
  height: 100%;
  width: 100vw;
  overflow-x: hidden; }

textarea:focus, input:focus, button:focus {
  outline: none; }

button {
  cursor: pointer; }

* {
  margin: 0;
}

.error-handler {
  color: cadetblue;
  text-align: center;
}

.error-handler__title {
  width: 50%;
  margin: 15% auto;
}

@media (max-width: 414px) {
  .error-handler__title {
    font-size: 2em;
    width: 80%;
    margin: 5% auto;
  }
}
/*# sourceMappingURL=ErrorHandler.css.map */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

body {
  color: white;
  font-family: 'Open Sans', sans-serif;
  height: 100%; }

.container-main {
  background-color: #f2f3f7;
  height: 100%;
  width: 100vw;
  overflow-x: hidden; }

textarea:focus, input:focus, button:focus {
  outline: none; }

button {
  cursor: pointer; }

/* Fondo */
.login {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background-color: #254370;
  min-height: -webkit-fill-available; }

.login__heading {
  width: 40%; }

/* Icono del Titulo */
.Trazado-75 {
  height: 45.6px;
  background-color: #254370; }

/* Nombre del Titulo */
.Trazado-74 {
  height: 31.3px;
  background-color: #ffffff; }

/* Texto */
.login h2 {
  margin: 0 auto 60px;
  font-family: 'Open Sans', sans-serif;
  font-size: 36px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #f2f2f2;
  width: 50%; }

/* Inputs */
.input-group {
  display: grid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0px auto 10px; }

input,
button,
select {
  border: none;
  width: 23vw; }

/* label */
.input-group label {
  height: 17px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff; }

/* Input */
.input-group input,
.input-group select {
  height: 32px;
  border-radius: 16px;
  background-color: #ffffff;
  padding: 0 10px 0 10px; }

/* Botones ingresar */
.formButton {
  margin: 0 auto 13px;
  display: block;
  height: 32.1px;
  border-radius: 16px;
  background-color: #0db8b2;
  color: #e7e7e7;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px; }

.login p {
  padding: 10px 0 15px;
  font-size: 12px; }

.login p.no-margin {
  margin: 0; }

/* Textos de botones ingresar */
.facebook {
  height: 17px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: 0.6px;
  text-align: left;
  color: #e7e7e7; }

/* Texto de abajo */
.google {
  height: 17px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff; }

.signup {
  color: #0db8b2;
  text-decoration: none; }

.errors {
  color: red; }

@media screen and (max-width: 736px) {
  .login {
    width: 100%; }
  .login h2 {
    width: 100%;
    font-size: 31px;
    margin: 0 auto 37px; }
  input,
  button,
  select {
    width: 67vw; } }

.social-networks {
  display: grid; }

.social-networks button {
  margin: 0 auto 13px;
  display: block;
  height: 32.1px;
  border-radius: 16px !important;
  background-color: #0db8b2 !important;
  color: #e7e7e7 !important;
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 12px !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important; }

.social-networks button span {
  font-weight: bold !important;
  margin: 0 auto; }

.social-networks div {
  display: none; }

.success.container.login {
  max-width: unset; }

@media (max-width: 736px) {
  .login {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; } }

/* Header */
.header {
  display: inline-flex;
  margin: 70px 0 20px; }

.header p {
  width: 85px;
  font-size: 9px;
  text-align: left;
  margin-top: 4px;
  margin-left: 10px; }

img {
  height: 45px; }

.card {
  color: #254370;
  background-color: #ffffff;
  margin: 15px 20px;
  box-shadow: 5px 5px 25px 0px #b1b1b180;
  width: 40%;
  border-radius: 15px;
  min-height: 275px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.card h3 {
  font-size: 1.4em;
}

.card-product-detail.clickable {
  cursor: pointer;
}

.card-more-dropdown ul {
  margin-left: -100px;
  position: absolute;
  background: #e6e6e6;
  box-shadow: 5px 5px 25px 0px #b1b1b180;
  margin-top: 5px;
  list-style: none;
  border-radius: 2px;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  transition: all 0.4s ease;
}

.card-more-dropdown ul li {
  padding: 10px;
  border-bottom: 1px solid #b1b1b184;
}

.card-more-dropdown ul a {
  text-decoration: none;
  display: flex;
  color: #254370;
  width: 100%;
  height: 100%;
}

.card-more-dropdown ul li:hover {
  background-color: #f7f7f7;
}

.card-more-dropdown button:focus + ul {
  opacity: 1;
  pointer-events: all;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.card-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 7px 0px 0px 18px;
  font-size: 10px;
  height: 30px;
  border-bottom: 1px solid #e6e6e680;
  margin: 0;
}

h3 {
  font-weight: 500;
}

.card-product-detail {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  border-bottom: 1px solid #e6e6e680;
}

.card-image {
  width: 55%;
  height: auto;
  background-color: #f4f6f8;
  text-align: center;
  position: relative;
}

.default-image {
  display: inline-flex;
  height: 100%;
  align-items: center;
}

.card-image img {
  width: 100%;
  height: 100%;
}

.card-detail {
  width: 60%;
  padding: 10px;
  height: auto;
}

.card-detail h5 {
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 3px;
}

.card-detail h2 {
  font-size: 12px;
}

.card-destiny {
  display: flex;
  flex-direction: row;
  font-size: 12px;
}

.card-destiny-map {
  width: 40%;
  align-items: right;
  text-align: right;
  padding-right: 10px;
}

.card-destiny-map a {
  width: 100%;
  display: block;
  margin: 20px auto;
  font-weight: 400px;
  padding-left: 45px;
  color: #0db8b2;
  text-decoration: none;
  text-transform: uppercase;
}

.card-destiny-direction {
  padding: 5px 0 0 15px;
  font-weight: 300;
  height: 100%;
  margin-right: 15px;
  width: 60%;
}

.card-destiny-direction h5 {
  font-size: 10px;
  font-weight: 300;
  text-transform: uppercase;
}

.card-destiny-direction h2 {
  font-size: 12px;
}

.detail-middle {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  width: 100%;
  justify-content: space-between;
}

.square {
  width: 100.5px;
  height: 100.5px;
  background-color: #0db8b2;
  display: flex;
  align-items: flex-end;
}

.square-container {
  width: 100%;
  padding: 8px;
}

.square .small-square {
  width: 30.9px;
  height: 15.5px;
  background-color: #12c4bb;
  float: right;
}

.dark-square {
  width: 23.2px;
  height: 100.5px;
  background-color: #1c8d87;
}

.package-size {
  display: inline-flex;
  align-items: center;
  bottom: 8px;
  right: 8px;
  position: absolute;
}

.size-square {
  width: 21.2px;
  height: 21px;
  border-radius: 1.5px;
  background-color: #0db8b2;
}

.size-square p {
  font-size: 10.6px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0;
  height: 100%;
  display: inline;
}

.size-dark-square {
  width: 4.5px;
  height: 21px;
  background-color: #1c8e88;
}

.card.no-margin-right {
  margin-right: 0;
}

.card.detail-margin {
  margin: 15px 7%;
}

@media (max-width: 767px) {
  .card-destiny {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}
/*# sourceMappingURL=Card.css.map */
.history-item {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  font-weight: 100;
  min-height: 45px;
  background: #ffffff;
  border-radius: 15px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.history-item h4 {
  font-size: 12px;
  padding-left: 14px;
}

.history-item .owner {
  width: 80px;
}

.history-item .address {
  width: 115px;
}

.history-item .term {
  width: 100px;
}

.history-item .term h4 {
  text-align: center;
}

.history-item .content {
  width: 100px;
}

.history-item .size {
  text-align: center;
  width: 90px;
}

.history-item .size span {
  font-size: 12px;
  height: 18px;
  display: flex;
  width: 20px;
  font-weight: 800;
  color: #f2f2f2;
  justify-content: center;
  background-color: #0db8b2;
  border-right: 4px solid #11a29d;
  margin: 0 auto;
}

.history-item .img-paquer {
  width: 80px;
  text-align: center;
}

.history-item .receiver {
  padding: 0 5px;
  width: 80px;
  text-align: center;
}

.history-item .sign {
  padding-left: 15px;
  margin-left: 10px;
  width: 60px;
  text-align: center;
}

.history-item .btn-outline-white {
  color: white;
  border: 1px solid white;
}

.history-item .btn-outline-white:hover {
  color: gray;
}

.history-item .delivered {
  display: flex;
  flex-direction: row;
  padding: 0 15px 0 30px;
  width: 100px;
}

.history-item .delivered span {
  background-color: #0db8b2;
  border-radius: 10px;
  padding: 2px 5px;
  color: #f2f2f2;
  font-weight: 500;
  border-right: 10px;
  margin-right: 10px;
}
/*# sourceMappingURL=HistoryItem.css.map */
/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

.accordion__section button {
  color: #254370;
  outline: none;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eee;
  color: #0db8b2;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  width: 100%;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  background-color: #0db8b2;
}

/* Style the accordion content title */
.accordion__title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

.accordion-label {
  margin: 0 10px;
  font-size: 16px;
}

.accordion-inline {
  display: inline-flex;
}

@media (max-width: 736px) {
  .accordion-label {
    margin: 0 5px;
    font-size: 12px;
  }
}

.btn-outline-white {
  color: white;
  border: 1px solid white;
}

.btn-outline-white:hover {
  color: gray;
}

@media (min-width: 1200px) {
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-0 {
    display: none;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-0 {
    display: none;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-0 {
    display: none;
  }
}

@media (max-width: 767px) {
  .col-xs-12 {
    width: 100%;
  }
  .col-xs-11 {
    width: 91.66666667%;
  }
  .col-xs-10 {
    width: 83.33333333%;
  }
  .col-xs-9 {
    width: 75%;
  }
  .col-xs-8 {
    width: 66.66666667%;
  }
  .col-xs-7 {
    width: 58.33333333%;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-5 {
    width: 41.66666667%;
  }
  .col-xs-4 {
    width: 33.33333333%;
  }
  .col-xs-3 {
    width: 25%;
  }
  .col-xs-2 {
    width: 16.66666667%;
  }
  .col-xs-1 {
    width: 8.33333333%;
  }
  .col-xs-0 {
    display: none;
  }
}

.backCool {
  background-color: #0db8b2 !important;
}

.BigImg {
  /* IMPORTANTE */
  position: relative;
  width: 40%;
  height: auto;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: .2em 1em;
}

.text-center {
  color: white;
  text-align: center !important;
}

.text-justify-black {
  color: rgba(10, 9, 10, 0.98);
  word-break: break-all;
  text-align: justify-all !important;
}

.modal-header {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.modal-footer {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.modal-content {
  border: none;
  border-radius: 15px;
  top: 147px;
}

.modal-body .row .col-lg-12 {
  text-align: center;
}
/*# sourceMappingURL=Accordion.css.map */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  outline: none;
}

.dashboard-container {
  padding: 0;
  margin: 0;
}

.pedidos,
.history {
  color: #254370;
  background-color: #f4f6f8;
}

.pedidos {
  height: 64px;
  display: flex;
  margin: 45px 0px 0px 10px;
}

.pedidos h3 {
  font-size: 38px;
  font-weight: 100;
  margin: 45px 120px 0 0;
}

.pedidos-bar {
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pedidos-bar-search {
  display: flex;
  align-items: center;
  height: 80px;
}

.pedidos-bar-search input,
.pedidos-bar-search button {
  height: 30px;
  border-radius: 15px;
  margin: 0 10px;
}

.pedidos-bar-search input {
  color: #254370;
  padding: 10px;
}

.pedidos-bar-search button {
  color: #f2f2f2;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  background-color: lightgrey;
}

.card-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.history {
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.history-title h3 {
  font-size: 38px;
  font-weight: 100;
  margin: 45px 0px 20px 10px;
}

.history-grid-wrapper {
  display: flex;
  flex-direction: column;
}

.history-top h4 {
  font-size: 12px;
  margin-left: 12%;
  text-transform: capitalize;
}

.history-top .address,
.history-top .delivered {
  margin-left: 1%;
}

.history-top .term,
.history-top .img-paquer {
  margin-left: 0.5%;
}

.history-subtitle {
  margin-left: 10px;
  text-transform: capitalize;
  font-size: 10px;
  padding: 0 5px;
  align-items: flex-start;
  margin-bottom: 15px;
}

.history-itemList {
  display: flex;
  flex-direction: column;
}

.history-accordion {
  list-style-type: none;
}

.user img {
  height: 32px;
}

.pedidos h3 {
  font-size: 38px;
  font-weight: 100;
  margin: 0;
}

.pedidos-bar-search input {
  color: #254370;
  padding: 10px;
}

.pedidos-bar-search button {
  color: #f2f2f2;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  background-color: #0db8b2;
  transition: background-color 0.2s ease-in;
}

.message-no-orders {
  color: #254370;
  padding-top: 2em;
}

.message-no-orders {
  color: #254370;
  padding-top: 2em;
  width: 85%;
  margin: 0 auto;
  text-align: center;
}

.message-no-historial {
  margin: 0 auto;
}

.pedidos-bar-search button.disabled {
  background-color: lightgrey;
  cursor: unset;
  transition: background-color 0.2s ease-in;
}

.collapse-buttons {
  margin: 20px auto;
  width: 85%;
  display: flex;
  justify-content: center;
}

.collapse-buttons button {
  padding: 10px;
  border-radius: 20px;
}

.collapse-buttons button:first-of-type {
  margin-right: 20px;
}

.dashboard-container .order-wrapper {
  background-color: transparent;
  color: #2c3062;
  width: 100%;
  margin: 0;
}

.dashboard-container .order-wrapper__heading.not-found {
  width: 60%;
  margin: 4% auto;
  color: cadetblue;
  text-align: center;
}

@media (max-width: 736px) {
  .pedidos-bar {
    display: block;
  }
  .card-section {
    display: block;
  }
  .pedidos {
    height: auto;
  }
  .pedidos h3 {
    width: 100%;
  }
  .pedidos-bar-search {
    margin-top: 15px;
    width: 100%;
    height: auto;
  }
  .pedidos-bar-search input {
    margin: 0px;
  }
  .card {
    margin: 15px;
    width: auto;
  }
  img {
    height: 40px;
  }
  .navbar {
    padding: 10px;
    height: 85px;
  }
  .navbar-logo {
    padding-left: 0;
  }
  .navbar-logo-text {
    display: none;
  }
  .navbar-userArea {
    width: auto;
    margin-right: 0;
  }
  .navbar-userArea-notifications {
    height: auto;
    margin-right: 8px;
  }
  .navbar-userArea span {
    font-size: 10px;
    width: 18px;
    height: 16px;
  }
  .navbar-userArea i {
    margin-top: 0;
  }
  svg {
    height: 19px;
    width: 20px;
  }
  .dropdown {
    height: auto;
    width: auto;
  }
  .user img {
    height: 22px;
    margin-right: 7px;
  }
  .user ul {
    margin-left: 9px;
  }
  .dropdown button {
    font-size: 12px;
    width: 100px;
  }
  .delivery-status-bar {
    padding: 0 8px;
    height: 63px;
  }
  .delivery-status-bar-back {
    width: 88px;
    padding-left: 3px;
  }
  .delivery-status-bar a {
    margin: 0;
    padding: 0px 0px 5px 0px;
    font-size: 9px;
    width: auto;
  }
  .delivery-status-bar-timer {
    width: auto;
    font-size: 16px;
    padding-left: 10%;
    margin-right: 0;
    text-align: center;
  }
  .delivery-status-bar-buttons {
    text-align: right;
    width: 176px;
    display: -ms-grid;
    display: grid;
  }
  .delivery-status-bar .underlined {
    border-bottom: none;
  }
  .card-section .card {
    width: auto;
    margin: 15px 7%;
  }
  .card.no-margin-right {
    margin: 15px 7%;
  }
}

@media (max-width: 1240px) {
  .history-grid-wrapper {
    display: none;
  }
}

@media (min-width: 1240px) {
  .history-accordion {
    display: none;
  }
}

@media (min-width: 1200px) {
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-0 {
    display: none;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-0 {
    display: none;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-0 {
    display: none;
  }
}

@media (max-width: 767px) {
  .col-xs-12 {
    width: 100%;
  }
  .col-xs-11 {
    width: 91.66666667%;
  }
  .col-xs-10 {
    width: 83.33333333%;
  }
  .col-xs-9 {
    width: 75%;
  }
  .col-xs-8 {
    width: 66.66666667%;
  }
  .col-xs-7 {
    width: 58.33333333%;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-5 {
    width: 41.66666667%;
  }
  .col-xs-4 {
    width: 33.33333333%;
  }
  .col-xs-3 {
    width: 25%;
  }
  .col-xs-2 {
    width: 16.66666667%;
  }
  .col-xs-1 {
    width: 8.33333333%;
  }
  .col-xs-0 {
    display: none;
  }
}

#row {
  position: relative;
  margin: auto;
  width: 100%;
  height: auto;
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 0px;
}

#row h4 {
  color: #0db8b2;
  background: white;
  text-align: center;
  color: #666;
  font-weight: 100;
  font-size: .8em;
  text-align: left;
  text-transform: capitalize;
  background: none;
}
/*# sourceMappingURL=Dashboard.css.map */
* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

.order-detail {
  background-color: #f2f3f7;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  background-color: #2c3062;
}

.logo-wrapper {
  display: flex;
  flex-direction: row;
}
/*# sourceMappingURL=OrderDetail.css.map */
.status-card__list__element {
  text-transform: capitalize;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  min-height: 60px;
}

.status-card__list__item__icon {
  display: block;
  background-color: white;
  border-radius: 15px;
  height: 25px;
  padding: 2px;
  width: 25px;
  margin: 10px 25px;
}

.status-card__list__item__paragraph {
  padding-top: 10px;
  font-size: 10px;
}

.status-card__list__element .square {
  margin-left: 15px;
  height: 45px;
  width: 45px;
}

.status-card__list__check {
  padding-bottom: 5px;
  color: #0db8b2;
}

.status-card__list__check.disabled {
  color: lightgray;
}

.Modal {
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid #cccccc;
  background: white;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  top: 147px;
  margin: 0 auto;
  width: 412px;
  height: 265px;
  border-radius: 15px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000006e;
}

.paquer-state__options a:not([href]):not([tabindex]) {
  color: black;
  cursor: pointer;
}

.attempt-photo {
  width: 100%;
  height: 100%;
}

@media (max-width: 736px) {
  .Modal {
    width: 346px;
    left: 15px;
    right: 15px;
  }
}

.MuiTooltip-popper .MuiTooltip-tooltipPlacementTop {
  background-color: white;
  color: #278d87;
  box-shadow: 2px 5px 15px 0px #b1b1b180;
  font-family: inherit;
  font-size: small;
}
/*# sourceMappingURL=styles.css.map */
.status-card {
  width: auto;
  padding-left: 10px;
  margin-top: 15px;
}

.paquer-state-displ {
  flex-direction: column;
  margin-left: 10px;
  color: #0db8b2;
  display: flex;
  height: 100%;
}

.paquer-state-displ.display-table {
  display: table;
}

.paquer-state__options {
  flex-direction: row;
  text-transform: uppercase;
}

.paquer-state__timer {
  padding: 0 2%;
  display: table-cell;
  vertical-align: middle;
}

.paquer-state__timer h3 {
  text-transform: uppercase;
}

.square {
  border-right: 7px solid #1c8d87;
}

.paquer-state__timer h3 {
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.paquer-state__timer h4 {
  font-size: 12px;
  margin: 10px 0px;
}

.paquer-state__options a {
  font-size: 12px;
  margin: 10px;
  color: black;
  text-decoration: none;
  padding-bottom: 3px;
  border-bottom: 1px solid black;
}

.paquer-state__options a:visited {
  color: black;
}

.--deliver {
  color: darkgray;
}

.past-status .paquer-state__options a {
  color: #2c3062;
  border-bottom: 1px solid #2c3062;
  font-size: 10px;
  margin-left: 0;
}

.past-status .paquer-state__options a:not([href]):not([tabindex]) {
  color: #2c3062;
}

.ReactModalPortal .paquer-card {
  position: static;
  position: initial;
}

.Modal {
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid #cccccc;
  background: white;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  top: 147px;
  margin: 0 auto;
  width: 412px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 15px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000006e;
}

.Modal .description {
  color: black;
  font-weight: 300;
}

.Modal .description span {
  color: #2c3062;
  font-weight: 600;
}

.attempt-photo {
  width: 100%;
  height: 277px;
}

a.margin-left-5 {
  margin-left: 5px;
}

@media (max-width: 736px) {
  .Modal {
    width: 346px;
    left: 15px;
    right: 15px;
  }
}
/*# sourceMappingURL=StatusCard.css.map */
.paquer-card {
  display: flex;
  flex-direction: column;
  color: #254370;
  border-radius: 15px;
  border: none;
  width: 380px;
  position: absolute;
  top: 27px;
  z-index: 5;
  background: white;
  left: 0;
  margin-top: 10px;
}

.title-wrapper-top__heading {
  font-size: 18px;
  position: absolute;
  display: flex;
  width: 100%;
  top: 5px;
  right: 0px;
}

.title-wrapper-top__heading .name {
  position: absolute;
  left: 10px;
}

.title-wrapper-top__heading .closeIcon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.title-wrapper-topwidth__heading {
  font-size: 14px;
  position: absolute;
  top: -28px;
  left: 10px;
}

.title__close {
  width: 30px;
}

.paquer-card__title-wrapper-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px 0 10px;
}

.img-wrapper__title {
  font-size: 11px;
  line-height: 1.2;
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 0;
}

.img-wrapper__subtitle {
  font-size: 16px;
  line-height: 1.2;
  margin: 0;
}

.img-wrapper__picture {
  width: 40%;
  height: 100%;
}

.paquer-card__img-wrapper {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #f7f7f9;
  border-bottom: 1px solid #f7f7f9;
}

.paquer-card__title-wrapper {
  margin: 0 10px 0 10px;
}

.paquer-card__contact {
  margin: 0 auto;
  height: 60px;
}

.contact-wrapper {
  margin: 10px;
}

.contact-wrapper__link,
.contact-wrapper__link:visited {
  color: #1cbbb1;
}

.contact-wrapper__link.disabled,
.contact-wrapper__link:visited.disabled {
  color: grey;
  cursor: unset;
}

.ReactModalPortal .paquer-card {
  width: 100%;
}

.paquer-card .contact-wrapper svg {
  height: 42px;
  font-size: 27px;
}

@media (max-width: 736px) {
  svg {
    height: auto;
    width: auto;
  }
}

@media (max-width: 414px) {
  .paquer-card {
    width: 200px;
  }
  .img-wrapper__title {
    font-size: 0.6em;
  }
  .img-wrapper__subtitle {
    font-size: 0.8em;
  }
  .paquer-card__contact {
    height: 26px;
    margin-top: -7px;
  }
  .contact-wrapper__link {
    font-size: 0.8em;
  }
  .whatsappIcon {
    font-size: 20px;
  }
}
/*# sourceMappingURL=PaquerCard.css.map */
.order-wrapper {
  background-color: #f2f3f7;
  color: #2c3062;
  width: 80%;
  margin: 95px auto;
}

.order-wrapper h1 {
  margin-left: 7%;
}

.order-wrapper__heading.not-found {
  width: 60%;
  margin: 15% auto;
  color: cadetblue;
  text-align: center;
}

.cards-wrapper {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1240px) {
  .cards-wrapper {
    margin-top: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .order-wrapper {
    width: 100%;
    margin: 12px 0 0 0;
  }
  .cards-wrapper {
    margin: 0;
  }
  .order-wrapper__heading {
    font-size: 26px;
  }
  .order-wrapper h1 {
    margin-left: 7%;
    margin-top: 30px;
  }
}
/*# sourceMappingURL=OrderWrapper.css.map */
.register.login h2 {
  font-size: 28px;
  margin: 0 auto; }

.container.success h2 {
  margin-top: 100px; }

.container.success h3 {
  font-size: 22px;
  font-weight: 200; }

.container.success button {
  margin-top: 50px; }

.phone-group select {
  width: 5vw;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px; }

.phone-group input {
  width: 18vw;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px; }

.terms-group {
  display: inline-flex;
  margin: 6px 0 15px; }

.terms-group input {
  width: 3vw;
  height: 20px; }

.terms-group p {
  padding: 0;
  width: 21vw;
  text-align: left; }

p.no-margin {
  margin: 0; }

@media screen and (max-width: 736px) {
  .phone-group select {
    width: 18vw; }
  .phone-group input {
    width: 49vw; }
  .terms-group input {
    width: 6vw; }
  .terms-group p {
    width: 54vw; } }

.mapdisplay {
  height: inherit;
}

.map-display__container {
  height: 80%;
  position: relative;
}

.destiny-image {
  width: 25px;
  height: 25px;
  left: -16px;
  top: -20px;
  z-index: 0 !important;
}

.start-marker {
  cursor: pointer;
  position: relative !important;
  display: flex;
  z-index: 15 !important;
}

.image-container {
  position: absolute;
  border-radius: 25px;
  left: -24px;
  top: -24px;
}

.paquer-image {
  width: 50px;
  height: 50px;
  object-fit: contain;
  position: absolute;
  border-radius: 25px;
  z-index: 10 !important;
}

.paquer-ellipsis-2 {
  width: 70px;
  height: 70px;
  opacity: 0.3;
  background-color: #0db8b2;
  border-radius: 40px;
  position: absolute;
  left: -34px;
  top: -34px;
  z-index: 10 !important;
}

.paquer-ellipsis {
  width: 82.7px;
  height: 82.7px;
  opacity: 0.2;
  background-color: #0db8b2;
  border-radius: 40px;
  position: absolute;
  left: -40px;
  top: -40px;
  z-index: 10 !important;
}

.H_ib {
  font-size: unset;
  line-height: unset;
  fill: white;
}

.H_ib .H_ib_content svg {
  height: 42px;
  font-size: 27px;
}

.H_ui {
  font-size: unset;
}

svg.H_icon {
  fill: grey;
}

.H_ib_body {
  height: 258px;
  width: 384px;
  border-radius: 14px;
  box-shadow: 0px 0 4px 0 #0f162154;
  background: white;
  padding: 0;
}

.origin-image {
  left: -30px;
  top: -20px;
  height: 30px;
  width: 30px;
  z-index: 0 !important;
  position: absolute;
}

.H_ui .paquer-card {
  width: 100%;
}

@media (max-width: 414px) {
  .H_ib_body {
    width: 210px;
    height: 213px;
  }
  .H_ib .H_ib_content svg {
    font-size: 10px;
    height: 24px;
  }
}
/*# sourceMappingURL=mapDisplay.css.map */
